.s18-section {
    color: #fff;
    padding: 6rem 2rem;
    position: relative;
    overflow: hidden;
}


.s18-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;
}

.s18-image-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 3rem;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
}

.s18-lauana-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.s18-image-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid #DAA520;
    border-radius: 50%;
    box-shadow: 0 10px 30px rgb(218, 165, 32);
    pointer-events: none;
}

.s18-image-decoration {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 2px solid #DAA520;
    border-radius: 50%;
}

.s18-image-decoration.left {
    top: -10px;
    left: -10px;
}

.s18-image-decoration.right {
    bottom: -10px;
    right: -10px;
}

.s18-title {
    font-size: 3rem;
    color: #DAA520;
    margin-bottom: 2rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 30px;

}

.s18-bio-content {
    margin-bottom: 2rem;
}

.s18-bio-item {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: white;
}

.s18-quote {
    font-size: 1.2rem;
    font-style: italic;
    color: #DAA520;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    padding: 1.5rem 0;
}


.s18-quote::before {
    top: 0;
    left: -20px;
}

.s18-quote::after {
    bottom: -20px;
    right: -20px;
}

@media (max-width: 768px) {
    .s18-image-wrapper {
        width: 250px;
        height: 250px;
    }

    .s18-title {
        font-size: 2.5rem;

    }

    .s18-lauana-image {
        transform: scale(1.1);
    }

    .s18-bio-item {
        font-size: 1rem;
    }

    .s18-quote {
        font-size: 1.1rem;
    }
}

@media (max-width: 360px) {
    .s18-section {
        padding: 3rem 1rem;
    }
    
    .s18-container {
        max-width: 100%;
    }
    
    .s18-image-wrapper {
        width: 200px;
        height: 200px;
        margin-bottom: 2rem;
    }
    
    .s18-lauana-image {
        transform: scale(1.1); /* Ajusta o posicionamento */
    }
    
    .s18-image-wrapper::after {
        border-width: 2px;
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.5); /* Sombra mais sutil */
    }
    
    .s18-image-decoration {
        width: 30px;
        height: 30px;
        border-width: 1px;
    }
    
    .s18-image-decoration.left {
        top: -5px;
        left: -5px;
    }
    
    .s18-image-decoration.right {
        bottom: -5px;
        right: -5px;
    }
    
    .s18-title {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        margin-top: 20px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
    
    .s18-bio-content {
        margin-bottom: 1.5rem;
    }
    
    .s18-bio-item {
        font-size: 0.9rem;
        margin-bottom: 0.8rem;
        line-height: 1.5;
        padding: 0 0.5rem;
    }
    
    .s18-quote {
        font-size: 1rem;
        padding: 1rem 0;
        max-width: 100%;
    }
    
    .s18-quote::before {
        left: -10px;
    }
    
    .s18-quote::after {
        right: -10px;
        bottom: -10px;
    }
}