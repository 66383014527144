.app {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.s7-section, .s8-section, .s9-section {
  min-height: 100vh;
}