.s17-section {
     
    color: #fff;
    padding: 6rem 2rem;
    position: relative;
    overflow: hidden;
}

.s17-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
    z-index: 1;
}

.s17-container {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.s17-title {
    font-size: 3rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.s17-description {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2.5rem;
    color: white;
    line-height: 1.6;
}

.s17-form-container {
    background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(218, 165, 32, 0.2);
    margin-bottom: 2rem;
}

.s17-contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.s17-form-group input {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.s17-form-group input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px rgba(218, 165, 32, 0.5);
}

.s17-submit-button {
    background-color: #DAA520;
    color: #000;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.s17-submit-button:hover {
    background-color: #FFD700;
    box-shadow: 0 0 20px rgba(218, 165, 32, 0.7);
}

.s17-note {
    font-size: 1rem;
    text-align: center;
    color: #DAA520;
    font-style: italic;
}

@media (max-width: 768px) {
    .s17-title {
        font-size: 2.5rem;
    }

    .s17-description {
        font-size: 1rem;
    }

    .s17-submit-button {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
    }
}

@media (max-width: 360px) {
    .s17-section {
        padding: 3rem 1rem; /* Reduz o padding da seção */
    }
    
    .s17-form-container {
        padding: 10px;
        border-radius: 10px; /* Reduz o border-radius */
        margin-bottom: 1rem;
    }

    .s17-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .s17-description {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
    }
    
    .s17-form-group input {
        padding: 0.8rem;
        font-size: 0.9rem;
    }
    
    .s17-submit-button {
        padding: 0.7rem 1.2rem;
        font-size: 0.9rem;
    }
    
    .s17-note {
        font-size: 0.8rem;
    }
    
    .s17-contact-form {
        gap: 1rem; /* Reduz o espaçamento entre os elementos do formulário */
    }
}

@media (max-width: 360px) {
    .s17-section {
        padding: 3rem 1rem;
        overflow-x: hidden; /* Previne scroll horizontal */
    }
    
    .s17-section::before {
        opacity: 0.7; /* Reduz um pouco a opacidade do gradiente */
    }
    
    .s17-container {
        max-width: 100%; /* Garante que o container não ultrapasse a largura da tela */
    }
    
    .s17-form-container {
        padding: 1rem;
        border-radius: 10px;
        margin-bottom: 1rem;
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.15); /* Sombra mais sutil */
    }

    .s17-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); /* Sombra mais sutil */
    }
    
    .s17-description {
        font-size: 0.9rem;
        margin-bottom: 1.5rem;
        line-height: 1.4; /* Reduz o line-height */
    }
    
    .s17-form-group input {
        padding: 0.8rem;
        font-size: 0.9rem;
        border-radius: 8px; /* Reduz o border-radius dos inputs */
    }
    
    .s17-form-group input:focus {
        box-shadow: 0 0 8px rgba(218, 165, 32, 0.4); /* Sombra mais sutil no focus */
    }
    
    .s17-submit-button {
        padding: 0.7rem 1.2rem;
        font-size: 0.9rem;
        border-radius: 25px; /* Reduz o border-radius do botão */
        gap: 0.3rem; /* Reduz o espaço entre ícone e texto */
    }
    
    .s17-submit-button:hover {
        box-shadow: 0 0 15px rgba(218, 165, 32, 0.5); /* Sombra mais sutil no hover */
    }
    
    .s17-note {
        font-size: 0.8rem;
        padding: 0 0.5rem; /* Adiciona padding lateral */
    }
    
    .s17-contact-form {
        gap: 0.8rem; /* Reduz ainda mais o gap */
    }
}