@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Poppins:wght@300;400;600&display=swap');

.s9-section {
    color: #fff;
      background-image: 
    radial-gradient(circle at 10% 20%, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 40%),
    radial-gradient(circle at 90% 80%, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 40%);

    padding: 8rem 2rem;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}


.s9-parallax-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, transparent 20%, #000 80%);
    z-index: 2;
}

.s9-container {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
}

.s9-main-title {
    font-size: 4.5rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 1rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.s9-subtitle {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 4rem;
    color: #fcf7ce;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.s9-pillars-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 4rem;
}

.s9-pillar-card {
    background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
    border-radius: 20px;
    padding: 2.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.s9-pillar-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(218, 165, 32, 0.3);
}

.s9-pillar-number {
    position: absolute;
    top: -70px;
    right: 10px;
    font-size: 10rem;
    color: rgba(218, 165, 32, 0.1);
    font-weight: bold;
    z-index: 1;
    font-family: 'Playfair Display', serif;
}

.s9-pillar-title {
    font-size: 1.8rem;
    color: #DAA520;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 2;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}

.s9-pillar-description {
    font-size: 1.1rem;
    line-height: 1.8;
    position: relative;
    z-index: 2;
    color: #faf4c1;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.s9-conclusion {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    
}

.s9-highlight-box {
    background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
    border-radius: 20px;
    padding: 2.5rem 3rem;
    position: relative;
    box-shadow: 0 15px 30px rgba(218, 165, 32, 0.15);
    overflow: hidden;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #fff;
    text-align: center;
}

.s9-highlight-box::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(218, 165, 32, 0.1) 0%, rgba(218, 165, 32, 0) 70%);
    animation: rotate 20s linear infinite;
    z-index: -1;
}

.s9-keyword {
    color: #DAA520;
    font-weight: 600;
    position: relative;
    display: inline-block;
}

.s9-keyword::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #DAA520, transparent);
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.s9-highlight-box:hover .s9-keyword::after {
    transform: scaleX(1);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.s9-conclusion h3 {
    font-size: 2.5rem;
    color: #DAA520;
    margin-bottom: 1.5rem;
    font-family: 'Playfair Display', serif;
}

.s9-conclusion p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.s9-conclusion p:last-child {
    font-style: italic;
    color: #DAA520;
    font-size: 1.4rem;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .s9-pillars-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .s9-pillars-grid {
        grid-template-columns: 1fr;
    }

    .s9-main-title {
        font-size: 3.5rem;
    }

    .s9-highlight-box {
        padding: 1rem 2rem;
    }

    .s9-conclusion p {
        font-size: .95rem;
    }

    .s9-subtitle {
        font-size: 1.5rem;
    }

    .s9-pillar-title {
        font-size: 1.5rem;
    }

    .s9-pillar-description {
        font-size: 1rem;
    }
}


@media (max-width: 360px) {
    .s9-section {
        padding: 4rem 1rem;
        min-height: auto;
    }
    
    .s9-main-title {
        font-size: 2rem;
        margin-bottom: 0.8rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
    
    .s9-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
    
    .s9-pillars-grid {
        gap: 1.5rem;
    }
    
    .s9-pillar-card {
        padding: 1.5rem;
        border-radius: 15px;
    }
    
    .s9-pillar-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.2);
    }
    
    .s9-pillar-number {
        font-size: 6rem;
        top: -40px;
        right: 5px;
    }
    
    .s9-pillar-title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    
    .s9-pillar-description {
        font-size: 0.9rem;
        line-height: 1.5;
    }
    
    .s9-conclusion {
        padding: 0 0.5rem;
    }
    
    .s9-conclusion h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .s9-conclusion p {
        font-size: 0.9rem;
        line-height: 1.6;
        margin-bottom: 1rem;
    }
    
    .s9-conclusion p:last-child {
        font-size: 1rem;
    }
    
    .s9-highlight-box {
        padding: 1.5rem;
        border-radius: 15px;
        font-size: 0.9rem;
        line-height: 1.5;
        box-shadow: 0 8px 15px rgba(218, 165, 32, 0.1);
    }
    
    .s9-keyword {
        font-weight: 500;
    }
    
    .s9-keyword::after {
        height: 1px;
    }
    
    /* Ajusta as animações para melhor performance */
    .s9-highlight-box::before {
        animation: rotate 30s linear infinite;
    }
    
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    /* Reduz a intensidade dos gradientes */
    .s9-section {
        background-image: 
            radial-gradient(circle at 10% 20%, rgba(218, 165, 32, 0.05) 0%, rgba(0, 0, 0, 0) 40%),
            radial-gradient(circle at 90% 80%, rgba(218, 165, 32, 0.05) 0%, rgba(0, 0, 0, 0) 40%);
    }
    
    .s9-parallax-overlay {
        background: radial-gradient(circle, transparent 10%, #000 90%);
    }
}