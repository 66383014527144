.s19-section {
    padding: 6rem 2rem;
    position: relative;
    overflow: hidden;
}

.s19-container {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.s19-title {
    font-size: 2.5rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.s19-content {
    position: relative;
}

.s19-paragraph-container {
    display: flex;
    margin-bottom: 2rem;
    position: relative;
}

.s19-paragraph-number {
    font-size: 3rem;
    color: rgba(218, 165, 32, 0.3);
    font-weight: bold;
    margin-right: 1rem;
    line-height: 1;
}

.s19-paragraph {
    font-size: 1.1rem;
    line-height: 1.8;
    color: white;
}

.s19-paragraph strong {
    color: #DAA520;
    font-weight: bold;
}

.s19-quote {
    font-size: 1.3rem;
    font-style: italic;
    color: #DAA520;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
    border-top: 1px solid rgba(218, 165, 32, 0.3);
    border-bottom: 1px solid rgba(218, 165, 32, 0.3);
}

@media (max-width: 768px) {
    .s19-title {
        font-size: 2rem;
    }

    .s19-paragraph-number {
        font-size: 2.5rem;
    }

    .s19-paragraph {
        font-size: 1rem;
    }

    .s19-quote {
        font-size: 1.1rem;
    }
}

@media (max-width: 360px) {
    .s19-section {
        padding: 3rem 1rem;
    }
    
    .s19-container {
        max-width: 100%;
    }
    
    .s19-title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
    
    .s19-paragraph-container {
        margin-bottom: 1.5rem;
        align-items: flex-start; /* Alinha o número com o topo do texto */
    }
    
    .s19-paragraph-number {
        font-size: 2rem;
        margin-right: 0.8rem;
        opacity: 0.2; /* Reduz ainda mais a opacidade */
    }
    
    .s19-paragraph {
        font-size: 0.9rem;
        line-height: 1.5;
    }
    
    .s19-paragraph strong {
        font-weight: 600; /* Reduz um pouco o peso da fonte */
    }
    
    .s19-quote {
        font-size: 1rem;
        margin-top: 2rem;
        padding: 0.8rem 1rem;
        line-height: 1.4;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-color: rgba(218, 165, 32, 0.2); /* Reduz a opacidade das bordas */
    }
    
    /* Adiciona espaço entre parágrafos dentro do container */
    .s19-paragraph-container + .s19-paragraph-container {
        margin-top: 1.2rem;
    }
    
    /* Ajusta o espaçamento quando houver quebra de linha */
    .s19-paragraph br {
        margin-bottom: 0.5rem;
    }
}