.s13-section {
   
  color: #fff;
  padding: 6rem 2rem;
  overflow: hidden;
}

.s13-dark-background {
  background-image: url('../assets/person-practicing-yoga-meditation-nature-sunset-sunrise.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.s13-dark-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 0;
}

.s13-container {
  position: relative;
  max-width: 1400px;
  margin: auto;
  z-index: 2;
}

.s13-section-title {
  font-size: 3.5rem;
  color: #DAA520;
  text-align: center;
  margin-bottom: 3rem;
  font-family: 'Playfair Display', serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.s13-elevated-video {
  transform: translateY(-100px);
  z-index: 1;
}

.s13-featured-videos {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 4rem;
  position: relative; /* Adicionado para permitir posicionamento absoluto do vídeo elevado */

}

.s13-video-testimonial {
  flex: 1;
  background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 10px 30px rgba(218, 165, 32, 0.2);
  transition: all 0.3s ease;
}

.s13-video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.s13-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 10px;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.s13-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktop-break {
  display: none; /* Por padrão, não mostra quebra de linha */
}

/* Em telas maiores (desktop), mostra a quebra de linha */
@media screen and (min-width: 768px) {
  .desktop-break {
    display: block;
  }
}

.s13-video-control-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(218, 165, 32, 0.7);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  padding: 15px; /* Área de toque maior para dispositivos móveis */
}

.s13-video-control-button:hover {
  background-color: rgba(218, 165, 32, 0.9);
}

.s13-control-icon {
  width: 30px;
  height: 30px;
  fill: #fff;
}

.s13-video-control-button.s13-hidden {
  opacity: 0;
  pointer-events: none;
}

.s13-video-title {
  font-size: 1.2rem;
  color: #DAA520;
  margin-top: 1rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.s13-carousel-container {
  margin-top: 4rem;
}

.s13-carousel-title {
  font-size: 2rem;
  color: #DAA520;
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'Playfair Display', serif;
}

.s13-swiper {
  width: 100%;
  height: auto;
  padding: 3em;
}

.s13-carousel-item {
  width: 100%;
  height: auto;
}

.s13-carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #DAA520;
}

.swiper-pagination-bullet {
  background: #DAA520;
}

.s13-video-carousel {
  margin-bottom: 4rem;
  width: 100%;
}

.s13-video-swiper {
  width: 100%;
  padding: 0 1rem 3rem;
}

.s13-video-swiper .swiper-slide {
  height: auto;
  display: flex;
  justify-content: center;
}

.s13-video-testimonial {
  flex: 1;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 10px 30px rgba(218, 165, 32, 0.2);
  transition: all 0.3s ease;
}

/* Adjust existing styling to work with carousel */
@media (max-width: 1024px) {
  .s13-featured-videos {
    flex-direction: column;
  }

  .s13-video-testimonial {
    max-width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) {
  .s13-featured-videos {
    flex-direction: column;
  }

  .s13-video-testimonial {
    max-width: 100%;
  }

  .s13-section-title {
    font-size: 3rem;
  }

  .s13-carousel-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .s13-section-title {
    font-size: 2.5rem;
  }

  .s13-carousel-title {
    font-size: 1.5rem;
  }

  .s13-video-control-button {
    width: 50px;
    height: 50px;
  }

  .s13-control-icon {
    width: 24px;
    height: 24px;
  }

  .s13-elevated-video {
    transform: translateY(0); /* Remove a elevação em telas menores */
  }
}

@media (max-width: 360px) {
  .s13-section {
      padding: 3rem 1rem;
  }
  
  .s13-section-title {
      font-size: 1.8rem;
      margin-bottom: 2rem;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .s13-featured-videos {
      gap: 1.5rem;
      margin-bottom: 2rem;
  }
  
  .s13-video-testimonial {
      border-radius: 10px;
      padding: 1rem;
      box-shadow: 0 5px 15px rgba(218, 165, 32, 0.15);
  }
  
  .s13-video-wrapper {
      border-radius: 8px;
  }
  
  .s13-video-control-button {
      width: 40px;
      height: 40px;
      padding: 10px;
  }
  
  .s13-control-icon {
      width: 20px;
      height: 20px;
  }
  
  .s13-video-title {
      font-size: 1rem;
      margin-top: 0.8rem;
  }
  
  .s13-carousel-container {
      margin-top: 2rem;
  }
  
  .s13-carousel-title {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
  }
  
  .s13-swiper {
      padding: 1.5em;
  }
  
  .s13-carousel-image {
      border-radius: 10px;
  }
  
  /* Ajustes nos controles do Swiper */
  .swiper-button-next,
  .swiper-button-prev {
      transform: scale(0.7); /* Reduz o tamanho das setas */
  }
  
  .swiper-button-next:after,
  .swiper-button-prev:after {
      font-size: 1.5rem !important; /* Força o tamanho menor da fonte do ícone */
  }
  
  .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
  }
  
  /* Otimização do background */
  .s13-dark-background::before {
      background-color: rgba(0, 0, 0, 0.95); /* Aumenta levemente a opacidade do overlay */
  }
  
  /* Ajustes para melhor performance em dispositivos móveis */
  .s13-dark-background {
      background-attachment: scroll; /* Remove o fixed para melhor performance */
  }
  
  /* Remove transformações complexas */
  .s13-elevated-video {
      transform: none;
  }
  
  /* Ajuste para espaçamento entre vídeos */
  .s13-featured-videos > * + * {
      margin-top: 1.5rem;
  }
}

/* Add these styles at the end of your Section13.css file */

/* Video Thumbnail Styles */
.s13-video-thumbnail-wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.s13-video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.s13-video-thumbnail-wrapper:hover .s13-video-thumbnail {
  transform: scale(1.05);
}

.s13-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.s13-video-thumbnail-wrapper:hover .s13-play-button {
  transform: translate(-50%, -50%) scale(1.1);
}

/* Modal Styles */
.s13-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.s13-modal-content {
  position: relative;
  width: 90%;
  max-width: 1000px;
  background-color: #111;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.7);
}

.s13-modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
  padding: 0;
}

.s13-modal-close:hover {
  background: rgba(255, 0, 0, 0.7);
}

.s13-modal-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.s13-modal-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .s13-modal-content {
    width: 95%;
  }
}

/* Vertical Video Styles */
.s13-vertical-modal {
  max-width: 500px; /* Narrower modal for vertical videos */
}

.s13-vertical-video-container {
  padding-bottom: 177.78%; /* 9:16 aspect ratio for vertical videos */
}

/* Make adjustments for mobile */
@media (max-width: 768px) {
  .s13-vertical-modal {
    max-width: 85%;
  }
  
  /* ...existing media query content... */
}

@media (max-width: 360px) {
  /* ...existing media query content... */
  
  .s13-vertical-modal {
    max-width: 90%;
  }
}