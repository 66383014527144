.s16-section {
     
    color: #DAA520;
    padding: 6rem 2rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.s16-section::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(218,165,32,0.05) 0%, rgba(0,0,0,0) 70%);
    animation: rotate 30s linear infinite;
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.s16-container {
    max-width: 1000px;
    width: 100%;
    z-index: 1;
}

.s16-main-title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
    color: #DAA520;
    text-shadow: 0 0 10px rgba(218,165,32,0.3);
}

.s16-subtitle {
    font-size: 1.8rem;
    margin-bottom: 3rem;
    color: #FFF;
    text-align: center;
    font-weight: 300;
    letter-spacing: 2px;
}

.s16-info-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
    min-height: 150px; /* Manter a altura mínima para evitar flickering */
    align-items: start; /* Alinhar cards ao topo em vez de esticar */
}

.s16-info-item {
    background: rgba(218, 165, 32, 0.05);
    border: 1px solid rgba(218, 165, 32, 0.1);
    border-radius: 10px;
    padding: 1.5rem;
    transition: all 0.3s ease;
    height: auto; /* Permitir que a altura se ajuste ao conteúdo */
    display: flex;
    flex-direction: column;
}

.s16-info-item:hover {
    background: rgba(218, 165, 32, 0.1);
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(218, 165, 32, 0.1);
}

.s16-info-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #DAA520;
}

.s16-info-content {
    font-size: 1rem;
    color: #FFF;
    opacity: 0.8;
}

/* Ajuste adicional para o caso "A ser anunciado" */
.s16-info-item:only-child {
    justify-self: center; /* Centraliza horizontalmente quando houver apenas um card */
    min-width: 280px; /* Define uma largura mínima para o card solitário */
    text-align: center; /* Centraliza o conteúdo do card */
}

.s16-presenter {
    text-align: center;
    margin-bottom: 4rem;
    padding: 3rem;
    background: linear-gradient(135deg, rgba(218, 165, 32, 0.05), rgba(218, 165, 32, 0.1));
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.s16-presenter::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(218,165,32,0.1) 0%, rgba(0,0,0,0) 70%);
    animation: rotateReverse 20s linear infinite;
}

@keyframes rotateReverse {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}

.s16-presenter-content {
    position: relative;
    z-index: 1;
}

.s16-presenter-name {
    font-size: 2.5rem;
    color: #DAA520;
    margin-bottom: 0.5rem;
    text-shadow: 0 0 10px rgba(218,165,32,0.3);
}

.s16-presenter-title,
.s16-presenter-specialty {
    font-size: 1.2rem;
    color: #FFF;
    opacity: 0.9;
    margin: 0.5rem 0;
}

.s16-cta-button {
    display: block;
    width: max-content;
    margin: 0 auto;
    padding: 1rem 3rem;
    background-color: #DAA520;
    color: #000;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 50px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.s16-cta-button::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
    transform: scale(0);
    transition: transform 0.5s ease-out;
}

.s16-cta-button:hover::before {
    transform: scale(1);
}


.mobile-break {
    display: none; /* Esconde por padrão */
}

@media (max-width: 768px) {
    .mobile-break {
        display: block; /* Mostra apenas em mobile */
    }
}

@media (max-width: 768px) {
    .s16-main-title {
        font-size: 2.3rem;
    }

    .s16-subtitle {
        font-size: 1.5rem;
    }

    .s16-info-container {
        grid-template-columns: 1fr;
        min-height: 180px; /* Reduzir a altura mínima para celulares */
    }

    .s16-presenter-name {
        font-size: 2rem;
    }
}

@media (max-width: 360px) {
    .s16-section {
        padding: 3rem 1rem;
        min-height: auto;
    }
    
    .s16-main-title {
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
        text-shadow: 0 0 5px rgba(218,165,32,0.2);
    }
    
    .s16-subtitle {
        font-size: 1rem;
        margin-bottom: 2rem;
        letter-spacing: 1px;
    }
    
    .s16-info-container {
        gap: 1rem;
        margin-bottom: 2rem;
        min-height: 150px; /* Altura ainda menor para telas muito pequenas */
    }
    
    .s16-info-item {
        padding: 1rem;
        border-radius: 8px;
    }
    
    .s16-info-item:hover {
        transform: translateY(-3px);
        box-shadow: 0 3px 10px rgba(218, 165, 32, 0.08);
    }
    
    .s16-info-title {
        font-size: 1rem;
        margin-bottom: 0.4rem;
    }
    
    .s16-info-content {
        font-size: 0.9rem;
    }
    
    .s16-presenter {
        margin-bottom: 2rem;
        padding: 1.5rem;
        border-radius: 15px;
    }
    
    .s16-presenter-name {
        font-size: 1.5rem;
        text-shadow: 0 0 5px rgba(218,165,32,0.2);
    }
    
    .s16-presenter-title,
    .s16-presenter-specialty {
        font-size: 0.9rem;
        margin: 0.3rem 0;
    }
    
    .s16-cta-button {
        padding: 0.8rem 2rem;
        font-size: 1rem;
        border-radius: 25px;
    }
    
    /* Otimiza as animações */
    .s16-section::before {
        animation: rotate 40s linear infinite; /* Reduz a velocidade */
    }
    
    .s16-presenter::before {
        animation: rotateReverse 30s linear infinite; /* Reduz a velocidade */
    }
    
    /* Ajusta os gradientes */
    .s16-section::before,
    .s16-presenter::before {
        opacity: 0.3; /* Reduz a opacidade dos efeitos */
    }
    
    /* Simplifica o efeito hover do botão */
    .s16-cta-button::before {
        display: none; /* Remove o efeito de gradiente no hover para melhor performance */
    }
    
    .s16-cta-button:hover {
        background-color: #FFD700; /* Simplifica o efeito hover */
        transform: translateY(-1px);
    }
}