.s6-section {
    padding: 50px 0;
    overflow: hidden;
    position: relative;
}

.s6-section::before,
.s6-section::after {
    content: '';
    position: absolute;
    top: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
        ellipse at center,
        rgba(218, 165, 32, 0.15) 0%,
        rgba(218, 165, 32, 0.05) 25%,
        rgba(0, 0, 0, 0) 70%
    );
    opacity: 0.2;
    animation: rotate 20s infinite linear;
}

.s6-section::before {
    left: -50%;
    transform: rotate(15deg);
}

.s6-section::after {
    right: -50%;
    transform: rotate(-15deg);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.s6-container {
    position: relative;
    z-index: 2;
}


.s6-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.s6-title-container {
    text-align: center;
    margin-bottom: 60px;
    position: relative;
}

.s6-subtitle {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #DAA520;
    margin-bottom: 20px;
    opacity: 0.8;
}

.s6-title {
    font-size: 72px;
    font-weight: 700;
    color: #DAA520;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.2;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
}

.s6-title span {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
}

.s6-title-underline {
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 4px;
    background: linear-gradient(90deg, #DAA520, #FFD700, #DAA520);
    border-radius: 2px;
}

.s6-content {
    color: #FFFFFF;
    max-width: 800px;
    margin: 0 auto;
}

.s6-description {
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
}

.s6-description li {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    padding-left: 30px;
    position: relative;
}

.s6-description li::before {
    content: '•';
    color: #DAA520;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: -2px;
}

.s6-quote {
    font-size: 24px;
    font-style: italic;
    color: #DAA520;
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    border-top: 1px solid #DAA520;
    border-bottom: 1px solid #DAA520;
}

.s6-cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #DAA520;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px 30px;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 40px;
}

.s6-cta-button:hover {
    background-color: #FFD700;
    box-shadow: 0 5px 15px rgba(212, 175, 55, 0.3);
}

.s6-logo-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 40px;
    overflow: hidden;
  }
  
  .s6-logo-wrapper {
    width: 100%;
    padding-top: 40%;
    position: relative;
    overflow: hidden;
  }
  
  .s6-logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

.s6-arrow-icon {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .s6-subtitle {
        font-size: 18px;
    }

    .s6-title {
        font-size: 36px;
    }

    .s6-description li {
        font-size: 16px;
    }

    .s6-quote {
        font-size: 20px;
    }

    .s6-cta-button {
        font-size: 12px;
        padding: 12px 24px;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

.s6-title::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #DAA520, #FFD700, #DAA520);
    background-size: 200% 100%;
    opacity: 0.1;
    animation: shimmer 3s infinite;
    pointer-events: none;
}

@media (max-width: 360px) {
    .s6-section {
        padding: 30px 0;
    }
    
    .s6-container {
        padding: 0 15px;
    }
    
    .s6-title-container {
        margin-bottom: 40px;
    }
    
    .s6-subtitle {
        font-size: 14px;
        letter-spacing: 2px;
        margin-bottom: 15px;
    }
    
    .s6-title {
        font-size: 28px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    
    .s6-title-underline {
        height: 2px;
        bottom: -8px;
    }
    
    .s6-description li {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 15px;
        padding-left: 20px;
    }
    
    .s6-description li::before {
        font-size: 18px;
        top: -1px;
    }
    
    .s6-quote {
        font-size: 16px;
        margin: 25px 0;
        padding: 15px;
    }
    
    .s6-cta-button {
        font-size: 11px;
        padding: 10px 20px;
        margin-top: 30px;
        letter-spacing: 0.5px;
    }
    
    .s6-logo-wrapper {
        padding-top: 50%; /* Aumenta um pouco para melhor visualização em telas pequenas */
    }
    
    .s6-arrow-icon {
        margin-left: 6px;
        width: 14px;
        height: 14px;
    }
    
    /* Ajusta as animações para telas menores */
    .s6-section::before,
    .s6-section::after {
        opacity: 0.1; /* Reduz a opacidade do efeito de fundo */
    }
    
    @keyframes shimmer {
        0% {
            background-position: -300px 0;
        }
        100% {
            background-position: 300px 0;
        }
    }
    
    /* Reduz o tamanho do container do logo */
    .s6-logo-container {
        margin-bottom: 25px;
    }
    
    /* Ajusta os efeitos de hover */
    .s6-cta-button:hover {
        box-shadow: 0 3px 10px rgba(212, 175, 55, 0.2);
    }
}