.s7-section {
    color: #fff;
    padding: 6rem 2rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.s7-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
        radial-gradient(
            circle at center,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.8) 70%,
            rgba(0, 0, 0, 1) 100%
        ),
        linear-gradient(
            45deg,
            rgba(218, 165, 32, 0.1) 0%,
            rgba(218, 165, 32, 0.05) 25%,
            rgba(0, 0, 0, 0) 50%,
            rgba(218, 165, 32, 0.05) 75%,
            rgba(218, 165, 32, 0.1) 100%
        );
    opacity: 0.8;
    pointer-events: none;
}

.s7-section::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: 
        radial-gradient(
            ellipse at center,
            rgba(218, 165, 32, 0.1) 0%,
            rgba(218, 165, 32, 0.05) 25%,
            rgba(0, 0, 0, 0) 70%
        );
    opacity: 0.4;
    animation: shimmer 15s infinite linear;
    pointer-events: none;
}

@keyframes shimmer {
    0% {
        transform: translate(-25%, -25%) rotate(0deg);
    }
    100% {
        transform: translate(-25%, -25%) rotate(360deg);
    }
}

.s7-container {
    position: relative;
    z-index: 1;
}

.s7-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.s7-title {
    font-size: 3.5rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 0 0 10px rgba(218, 165, 32, 0.3);
}

.s7-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.s7-image-column {
    flex: 1;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
}

.s7-image-frame {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 30px rgba(218, 165, 32, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: auto;
}

.s7-image-frame::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    border-radius: 50%;
    filter: blur(10px);
    opacity: 0.7;
}

.s7-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.s7-image-frame:hover .s7-main-image {
    transform: scale(1.05);
}

.s7-text-column {
    flex: 1;
}

.s7-opportunity-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
}

.s7-opportunity-list li {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
    position: relative;
}

.s7-opportunity-list li::before {
    content: '✧';
    color: #DAA520;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.s7-quote {
    font-size: 1.3rem;
    font-style: italic;
    color: #DAA520;
    text-align: center;
    margin-top: 2rem;
    position: relative;
}


.s7-quote::after {
    left: 94%;
}

.s7-quote::before {
    left: 0;
}

.s7-quote::after {
    right: 0;
}

@media (max-width: 768px) {
    .s7-content {
        flex-direction: column;
    }

    .s7-image-column {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .s7-image-frame {
        width: 300px;
        height: 300px;
    }

    .s7-title {
        font-size: 2.5rem;
    }

    .s7-opportunity-list li,
    .s7-quote {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {
    .s7-section {
        padding: 3rem 1rem;
        min-height: auto;
    }
    
    .s7-container {
        width: 100%;
    }
    
    .s7-title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        text-shadow: 0 0 5px rgba(218, 165, 32, 0.2);
    }
    
    .s7-image-column {
        margin-bottom: 1.5rem;
    }
    
    .s7-image-frame {
        width: 200px; /* Reduz ainda mais o tamanho da imagem */
        height: 200px;
        padding: 5px;
        box-shadow: 0 0 15px rgba(218, 165, 32, 0.3);
    }
    
    .s7-image-frame::before {
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        filter: blur(5px);
    }
    
    .s7-image-frame:hover .s7-main-image {
        transform: scale(1.03); /* Reduz o efeito de escala no hover */
    }
    
    .s7-opportunity-list {
        margin-bottom: 1.5rem;
    }
    
    .s7-opportunity-list li {
        font-size: 0.9rem;
        margin-bottom: 0.8rem;
        padding-left: 1.5rem;
        line-height: 1.4;
    }
    
    .s7-opportunity-list li::before {
        font-size: 0.9rem;
    }
    
    .s7-quote {
        font-size: 1rem;
        margin-top: 1.5rem;
    }
    
    /* Ajusta a animação do shimmer para telas menores */
    @keyframes shimmer {
        0% {
            transform: translate(-15%, -15%) rotate(0deg);
        }
        100% {
            transform: translate(-15%, -15%) rotate(360deg);
        }
    }
    
    /* Reduz a opacidade dos efeitos de fundo */
    .s7-section::before {
        opacity: 0.6;
    }
    
    .s7-section::after {
        opacity: 0.3;
    }
    
    .s7-text-column {
        text-align: left; /* Centraliza o texto em telas muito pequenas */
    }
}