.s20-section {
     
    color: #fff;
    padding: 6rem 2rem;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.s20-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(218, 165, 32, 0.2) 0%, rgba(0, 0, 0, 0) 70%);
    z-index: 1;
}

.s20-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 100%;
}

.s20-title {
    font-size: 3rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Playfair Display', serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.s20-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.s20-video-wrapper {
    width: 100%;
    max-width: 340px; /* Largura típica de um story de Instagram */
    margin-bottom: 2rem;
}

.s20-video-container {
    position: relative;
    padding-bottom: 177.78%; /* Proporção de aspecto 9:16 */
    height: 0;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(218, 165, 32, 0.3);
}

.s20-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.s20-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #DAA520;
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(218, 165, 32, 0.3);
    margin-top: 2rem;
}

.s20-button:hover {
    background-color: #FFD700;
    box-shadow: 0 8px 25px rgba(218, 165, 32, 0.5);
    transform: translateY(-3px);
}

@media (max-width: 768px) {
    .s20-title {
        font-size: 2.5rem;
    }

    .s20-video-wrapper {
        max-width: 280px;
    }

    .s20-button {
        font-size: 1.1rem;
        padding: 0.8rem 1.6rem;
    }
}

@media (max-width: 480px) {
    .s20-section {
        padding: 4rem 1rem;
    }

    .s20-title {
        font-size: 2rem;
    }

    .s20-video-wrapper {
        max-width: 240px;
    }

    .s20-button {
        font-size: 1rem;
        padding: 0.7rem 1.4rem;
    }
}

@media (max-width: 360px) {
    .s20-section {
        padding: 3rem 1rem;
        min-height: auto;
    }
    
    .s20-section::before {
        background: radial-gradient(circle at center, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 60%);
    }
    
    .s20-container {
        max-width: 100%;
    }
    
    .s20-title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
    
    .s20-content-wrapper {
        gap: 1.5rem;
    }
    
    .s20-video-wrapper {
        max-width: 200px; /* Reduz ainda mais para telas muito pequenas */
        margin-bottom: 1.5rem;
    }
    
    .s20-video-container {
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.2);
    }
    
    .s20-button {
        font-size: 0.9rem;
        padding: 0.6rem 1.2rem;
        border-radius: 25px;
        margin-top: 1.5rem;
        width: 90%; /* Garante que o botão não fique muito largo */
        max-width: 200px;
        box-shadow: 0 3px 10px rgba(218, 165, 32, 0.2);
    }
    
    .s20-button:hover {
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.3);
        transform: translateY(-2px);
    }
    
    /* Ajusta o espaçamento entre os ícones e texto no botão */
    .s20-button > * {
        margin: 0 10px;
    }
}