.s5-section {
     
    color: #fff;
    padding: 6rem 2rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}


.s5-container {
    max-width: 1000px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.s5-title {
    font-size: 3rem;
    color: #DAA520;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Playfair Display', serif;
}

.s5-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.s5-question-box {
    background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
    border: 1px solid rgba(218, 165, 32, 0.3);
    border-radius: 15px;
    padding: 2rem;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 10px 30px rgba(218, 165, 32, 0.1);
}

.s5-active-question {
    font-size: 1.3rem;
    color: #DAA520;
    font-weight: 500;
}

.s5-question-prompt {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
}

.s5-questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.s5-question-button {
    background-color: rgba(218, 165, 32, 0.1);
    border: 1px solid #DAA520;
    color: #DAA520;
    padding: 0.8rem 1.5rem;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.s5-question-button:hover,
.s5-question-button.active {
    background-color: #DAA520;
    color: #000;
}

.s5-insight {
    text-align: center;
    font-style: italic;
    color: #DAA520;
    margin-top: 2rem;
}

.s5-explanation {
    margin-top: 4rem;
    text-align: center;
}

.s5-explanation p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.s5-highlight {
    color: #DAA520;
    font-weight: bold;
}

@media (max-width: 768px) {
    .s5-title {
        font-size: 2.5rem;
    }

    .s5-question-box {
        padding: 1.5rem;
        min-height: 120px;
    }

    .s5-active-question {
        font-size: 1.1rem;
    }

    .s5-question-button {
        font-size: 0.9rem;
        padding: 0.6rem 1.2rem;
    }

    .s5-explanation p {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {
    .s5-section {
        padding: 3rem 1rem;
        min-height: auto;
    }
    
    .s5-container {
        max-width: 100%;
    }
    
    .s5-title {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }
    
    .s5-content {
        gap: 1.5rem;
    }
    
    .s5-question-box {
        padding: 1rem;
        min-height: 100px;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.1);
    }
    
    .s5-active-question {
        font-size: 0.95rem;
    }
    
    .s5-question-prompt {
        font-size: 0.9rem;
    }
    
    .s5-questions {
        gap: 0.8rem;
    }
    
    .s5-question-button {
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
        border-radius: 25px;
        /* Garante que os botões ocupem toda a largura */
        width: 100%;
        text-align: center;
    }
    
    .s5-insight {
        font-size: 0.9rem;
        margin-top: 1.5rem;
    }
    
    .s5-explanation {
        margin-top: 2rem;
    }
    
    .s5-explanation p {
        font-size: 0.9rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }
    
    .s5-highlight {
        font-size: 0.9rem;
    }
}