.s1-container {
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 20%),
    radial-gradient(circle at 90% 80%, rgba(218, 165, 32, 0.1) 0%, rgba(0, 0, 0, 0) 20%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
}

.s1-logo-container {
  width: 100%;
  max-width: 800px; /* Adjust based on your needs */
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.s1-logo-wrapper {
  width: 100%;
  padding-top: 50%; /* Adjust this value to control how much of the logo is visible */
  position: relative;
  overflow: hidden;
}

.s1-logo-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center; /* Adjust this to fine-tune the visible part of the logo */
}

.s1-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  z-index: 2;
}

.s1-text-content {
  max-width: 900px; /* Increased from 800px */
  text-align: center;
  padding: 0 2rem;
}

.s1-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #DAA520;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.s1-title {
  font-family: 'Playfair Display', serif;
  font-size: 4rem;
  font-weight: 700;
  color: #DAA520;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
}

.s1-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #DAA520;
}

.s1-description {
  font-family: 'Roboto', sans-serif;
  font-size: 1.15rem; /* Increased from 1rem */
  color: #fff;
  line-height: 1.9; /* Slightly increased for better readability */
  margin-bottom: 2rem;
}

.s1-description p {
  margin-bottom: 1.5rem;
}

.s1-highlight {
  font-size: 1.4rem; /* Increased from 1.2rem */
  font-weight: 700;
  color: #DAA520;
}

.s1-emphasis {
  font-weight: 700;
  border-bottom: 1px solid #DAA520;
  padding-bottom: 2px;
  transition: all 0.3s ease;
}

.s1-emphasis:hover {
  color: #DAA520;
  border-bottom: 1px solid #fff;
}

.s1-glow {
  color: #DAA520;
  text-shadow: 0 0 5px #DAA520, 0 0 10px #DAA520;
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #DAA520, 0 0 10px #DAA520;
  }
  to {
    text-shadow: 0 0 10px #DAA520, 0 0 20px #DAA520;
  }
}

.s1-cta-button {
  background-color: #DAA520;
  color: #000;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(218, 165, 32, 0.3);
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.s1-cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.5s;
}

.s1-cta-button:hover::before {
  left: 100%;
}

.s1-cta-button:hover {
  background-color: #FFD700;
  box-shadow: 0 8px 20px rgba(218, 165, 32, 0.5);
}

.s1-arrow-icon {
  margin-left: 10px;
}

/* Remove video container styles or keep for future use */
.s1-video-container, .s1-video-mobile, .s1-video-desktop {
  display: none;
}

.s1-main-image-container {
  width: 100%;
  max-width: 800px; /* Increased to match logo container width */
  margin: -80px auto 3rem; /* Changed from 0.2rem to -80px to create overlap */
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2; /* Ensure main image appears above logo */
}

.s1-main-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.4s ease;
}

.s1-main-image-container:hover .s1-main-image {
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .s1-content {
    flex-direction: column;
    align-items: center;
  }

  .s1-logo-image {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .s1-text-content {
    max-width: 100%;
    padding: 0 1rem;
    margin: 0;
  }

  .s1-content {
    flex-direction: column;
  }

  .s1-text-content {
    width: 100%;
  }

  .s1-content {
    flex-direction: column;
    align-items: stretch;
  }

  .s1-logo-image {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  }

  .s1-text-content,
  .s1-video-container {
    max-width: 100%;
    padding-right: 0;
    margin: 0;
  }

  .s1-video-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .s1-text-content {
    margin-bottom: 2rem;
  }

  .s1-cta-button {
    font-size: 0.9em;
    margin: auto;
  }

  .s1-title {
    font-size: 3rem;
  }

  .s1-description {
    font-size: 1.05rem; /* Increased from 0.9rem */
  }

  .s1-main-image-container {
    max-width: 100%;
    margin: -20px auto 2rem; /* Reduced overlap from -40px to -20px */
  }
}

@media (max-width: 360px) {
  .s1-container {
      padding: 1rem;
      min-height: auto;
  }
  
  .s1-logo-wrapper {
      padding-top: 40%; /* Reduz o espaço reservado para o logo */
  }
  
  .s1-logo-image {
      width: 90%; /* Reduz um pouco a largura da imagem */
  }
  
  .s1-subtitle {
      font-size: 1rem;
      letter-spacing: 1px;
  }
  
  .s1-title {
      font-size: 2rem;
      margin-bottom: 1rem;
  }
  
  .s1-title::after {
      width: 40px; /* Reduz o tamanho da linha decorativa */
      height: 2px;
  }
  
  .s1-description {
      font-size: 0.95rem; /* Increased from 0.85rem */
      line-height: 1.6;
      margin-bottom: 1.5rem;
  }
  
  .s1-highlight {
      font-size: 1.1rem; /* Increased from 1rem */
  }
  
  .s1-cta-button {
    font-size: 11px;
    padding: 10px 20px;
    margin-top: 30px;
    letter-spacing: 0.5px;
}
  
  .s1-video-wrapper {
      margin-top: 20px;
      margin-bottom: 20px;
  }
  
  .s1-arrow-icon {
      margin-left: 5px;
  }
  
  .s1-text-content {
      margin-bottom: 1.5rem;
  }
  
  .s1-video-mobile {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
  }

  .s1-main-image-container {
    margin: -15px auto 1.8rem; /* Reduced overlap from -30px to -15px */
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
}