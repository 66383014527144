.s14-section {
    background-color: #000;
    color: #fff;
    padding: 6rem 2rem;
    position: relative;
    overflow: hidden;
    z-index: 3;
}

.s14-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
}

.s14-quote-box {
    background: linear-gradient(145deg, rgba(218, 165, 32, 0.1), rgba(0, 0, 0, 0.3));
    border-radius: 20px;
    padding: 4rem;
    position: relative;
    box-shadow: 0 10px 30px rgba(218, 165, 32, 0.2);
}

.s14-decorative-line {
    position: absolute;
    height: 2px;
    background-color: #DAA520;
    width: 100px;
}

.s14-line-left {
    top: 20px;
    left: 20px;
}

.s14-line-right {
    bottom: 20px;
    right: 20px;
}

.s14-quote-icon {
    font-size: 4rem;
    color: #DAA520;
    position: absolute;
    opacity: 0.5;
}

.s14-quote-open {
    top: 20px;
    left: 50px;
}

.s14-quote-close {
    bottom: 20px;
    right: 50px;
}

.s14-quote-text {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.6;
    font-family: 'Playfair Display', serif;
    color: white;
}

.s14-decorative-element {
    font-size: 2rem;
    color: #DAA520;
    position: absolute;
    opacity: 0.3;
}

.s14-element-left {
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
}

.s14-element-right {
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {

    .s14-quote-icon {
        font-size: 2.5rem;
    }
    

    .s14-quote-text {
        font-size: 1rem;
    }

    .s14-quote-open {
        top: 20px;
        left: 10px;
    }
    
    .s14-quote-close {
        bottom: 20px;
        right: 10px;
    }
    
    .s14-quote-box {
        padding: 3rem 2rem;
    }


    .s14-decorative-element {
        font-size: 0.8rem;
    }
}

@media (max-width: 360px) {
    .s14-section {
        padding: 3rem 1rem;
    }
    
    .s14-container {
        margin-top: 25px;
    }
    
    .s14-quote-box {
        padding: 2rem 1rem;
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(218, 165, 32, 0.15);
    }
    
    .s14-decorative-line {
        width: 50px;
        height: 1px;
    }
    
    .s14-line-left {
        top: 15px;
        left: 10px;
    }
    
    .s14-line-right {
        bottom: 15px;
        right: 10px;
    }
    
    .s14-quote-icon {
        font-size: 2rem;
        opacity: 0.4;
    }
    
    .s14-quote-open {
        top: 15px;
        left: 8px;
    }
    
    .s14-quote-close {
        bottom: 15px;
        right: 8px;
    }
    
    .s14-quote-text {
        font-size: 0.9rem;
        line-height: 1.5;
        padding: 0 0.5rem;
    }
    
    .s14-decorative-element {
        font-size: 0.6rem;
        opacity: 0.2;
    }
    
    .s14-element-left {
        left: 4%;
    }
    
    .s14-element-right {
        right: 4%;
    }
}