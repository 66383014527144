.s3-section {
    background-color: #000;
    color: #fff;
    padding: 6rem 2rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.s3-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.s3-column {
    flex: 1;
}

.s3-image-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.s3-main-image {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(218, 165, 32, 0.3);
    transition: transform 0.5s ease-out;
}

.s3-main-image:hover {
    transform: scale(1.02);
}

.s3-content-column {
    padding-left: 4rem;
}

.s3-title {
    font-size: 2.5rem;
    color: #DAA520;
    margin-bottom: 2rem;
    font-family: 'Playfair Display', serif;
    position: relative;
}

.s3-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: #DAA520;
}

.s3-feature-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
}

.s3-feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.s3-feature-item svg {
    flex-shrink: 0;
    margin-right: 1rem;
    margin-top: 0.2rem;
}

.s3-cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DAA520;
    color: #000;
    padding: 1rem 2rem;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
    transition: all 0.4s ease;
    text-align: center;
    max-width: 40%;
}

.s3-cta-button:hover {
    background-color: #FFD700;
    box-shadow: 0 0 20px rgba(218, 165, 32, 0.5);
    transform: translateY(-2px);
}

.s3-section {
    position: relative;
    overflow: hidden;
}
.s3-background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.15;
    filter: brightness(50%);
    z-index: 0;
}

.s3-arrow-icon {
    margin-left: 10px;
}

.s3-container {
    position: relative;
    z-index: 1;
}

@media (max-width: 768px) {
    .s3-container {
        flex-direction: column;
    }

    .s3-content-column {
        padding-left: 0;
        margin-top: 3rem;
    }

    .s3-cta-button {
        font-size: 0.9em;
        margin: auto;
        max-width: 100%;
      }
    

    .s3-title {
        font-size: 2rem;
    }

    .s3-feature-item {
        font-size: 1rem;
    }
}

@media (max-width: 360px) {
    .s3-section {
        padding: 3rem 1rem;
        min-height: auto;
    }
    
    .s3-main-image {
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(218, 165, 32, 0.2);
    }
    
    .s3-content-column {
        margin-top: 2rem;
    }
    
    .s3-title {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }
    
    .s3-title::after {
        width: 40px;
        height: 2px;
        bottom: -8px;
    }
    
    .s3-feature-list {
        margin-bottom: 1.5rem;
    }
    
    .s3-feature-item {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        line-height: 1.4;
    }
    
    .s3-feature-item svg {
        width: 16px; /* Reduz o tamanho do ícone */
        height: 16px;
        margin-right: 0.8rem;
        margin-top: 0.1rem;
    }
    
    .s3-cta-button {
        font-size: 11px;
        padding: 10px 20px;
        margin-top: 30px;
        letter-spacing: 0.5px;
    }
    
    .s3-cta-button:hover {
        box-shadow: 0 0 10px rgba(218, 165, 32, 0.4);
        transform: translateY(-1px);
    }
    
    .s3-arrow-icon {
        margin-left: 10px;
    }
    
    .s3-background-image {
        opacity: 0.1; /* Reduz ainda mais a opacidade do background para melhor legibilidade */
    }
    
    .s3-main-image:hover {
        transform: scale(1.01); /* Reduz o efeito de escala no hover */
    }
}